import React, { Component } from "react";
import Select from "react-select";
import Nav from '../Shared/Nav';
import SignUpButton from "../Notification/SignUpButton";
import ResultDetail from "./ResultDetail";
import LatestMeetings from "./LatestMeetings";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import Toast from "../Notification/Toast";
import ReactGA from 'react-ga4';
import { getCookie } from "../../getCookie";

// connector for elastic
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

// react + elastic UI components
import {
  SearchProvider,
  WithSearch,
  isLoading,
} from "@elastic/react-search-ui";

// helper functions to configure search
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';


// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();




/** original elasticsearch connector
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
**/


/** new custom connector **/
class CustomAPIConnector {
  constructor(apiEndpoint) {
    this.apiEndpoint = apiEndpoint;
    this.timeoutDuration = 10000;
  }
  async onSearch(state, queryConfig) {
    try {
      const fetchWithTimeout = (url, options, timeout = this.timeoutDuration) => {
        return Promise.race([
          fetch(url, options),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error("Request timed out")), timeout)
          )
        ]);
      };
      const response = await fetchWithTimeout(this.apiEndpoint, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
        body: JSON.stringify(state),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error occurred during onSearch:", error);
      alert("An error occurred during search, please try again. Please contact support if the problem persists.");
      return { error: true, message: error.message || "Something went wrong during the search." };
    }
  }
}



const connector = new CustomAPIConnector("/user_auth/search/");

const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
  initialState: {
    filters: [
      { field: "segment", values: [0] }
    ],
    resultsPerPage: 100,
    sortDirection: 'desc',
    sortField: 'upload_date'
  }
};


// dict for date formats
const month_names = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

// react-select config
const sortOptions = [
  { value: ['segment', 'asc'], label: 'Meeting timestamp (ascending)' },
  { value: ['segment', 'desc'], label: 'Meeting timestamp (descending)' },
  { value: ['upload_date', 'desc'], label: 'Upload date (newest)'},
  { value: ['upload_date', 'asc'], label: 'Upload date (oldest)'}
];

const dropdownStyle = {
  control: (base, state) => ({
    ...base,
    background: state.isFocused ? 'white' : 'white',
    borderRadius: '0px',
    borderColor: 'black',
    // need these nulls to override the default styles
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null
    }
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  })
};

class Search extends Component {
  state = {
    selectedOption: null,
    drawer: {
      isOpen: false,
      currentVideo: null,  // video_url
      currentSegment: null // segment_start for search results
    },
    local: [],
    state: [],
    search: "",
    sort: [],
    sortLabel: "",
    toast: {
      notified: '',
      state: false
    },
    localityOptions: [],
    stateOptions: []
  };

  constructor(props) {
      super(props);
      this.state = {
        selectedOption: null,
        drawer: {
          isOpen: false,
          currentVideo: null,
          currentSegment: null
        },
        local: [],
        state: [],
        search: "",
        sort: [],
        sortLabel: "",
        toast: {
          notified: '',
          state: false
        },
        localityOptions: [],
        stateOptions: []
      }

      this.handleAddAlertClick = this.handleAddAlertClick.bind(this);
  }

  componentDidMount() {
    this.fetchLocalities();
  }

  fetchLocalities = async () => {
    try {
      const response = await fetch("/user_auth/user-localities/", {
        headers: {
          "Authorization": "Token " + localStorage.getItem("auth-token"),
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      this.setState({
        localityOptions: data.localities || [],
        stateOptions: data.states || []
      });
    } catch (error) {
      console.error("Failed to fetch localities:", error);
      alert("Could not load localities. Please try again later.");
    }
  };

  handleLocalChange = (e) => {
    
    this.setState({local: (Array.isArray(e) ? e.map((x) => x.value) : [])});
    //console.log(this.state.local);
  };

  handleStateChange = (e) => {
    
    this.setState({state: (Array.isArray(e) ? e.map((x) => x.value) : [])});
    //console.log(this.state.state);
  };

  findDrawer = (result, isLatestMeeting = false) => {
    console.log('Search.findDrawer called with:', { result, isLatestMeeting });
    
    // Validate required fields
    if (!result?.video_url?.raw) {
      console.error('Invalid result - missing video_url:', result);
      return;
    }

    // Only set segment for search results, not latest meetings
    const segment = isLatestMeeting ? null : ('transcript' in result && result?.segment_start?.raw) ? result.segment_start.raw : null;

    // Close existing drawer first if switching videos
    if (this.state.drawer.isOpen && this.state.drawer.currentVideo !== result.video_url.raw) {
      this.setState({
        drawer: {
          isOpen: false,
          currentVideo: null,
          currentSegment: null
        }
      }, () => {
        // Wait for animation to complete before opening new drawer
        setTimeout(() => {
          this.setState({ 
            drawer: {
              isOpen: true,
              currentVideo: result.video_url.raw,
              currentSegment: segment
            }
          });
        }, 300); // Match the duration prop on the Drawer component
      });
    } else {
      // Open drawer directly if closed or same video
      this.setState({ 
        drawer: {
          isOpen: true,
          currentVideo: result.video_url.raw,
          currentSegment: segment
        }
      });
    }

    // "view more" GA event
    ReactGA.event({
      category: "view-more",
      action: "click-view-more",
      label: "click-view-more-search_page",
    });
  }

  closeDrawer = () => {
    console.log('Search.closeDrawer called');
    
    // Reset all drawer state fields
    this.setState({ 
      drawer: {
        isOpen: false,
        currentVideo: null,
        currentSegment: null
      }
    });
  }

  handleToast = (newVal) => {
    this.setState({ toast: newVal })
  }

  handleAddAlertClick() {
    ReactGA.event({
      category: "alert",
      action: "click-add-alert",
      label: "click-add-alert-search_page",
    });
  }

  render() {
    ReactGA.send({
      hitType: "pageview",
      page:"/search",
      title:"search"
    });

    return (
      <div>
        <header className="sticky top-0 border-b-2 border-theme-gray">
          <Nav loggedIn={this.props.loggedIn} username={this.props.username}/>
        </header>
        <div className="Search relative">
          <SearchProvider config={config}>
            <WithSearch
              mapContextToProps={({ searchTerm, setSearchTerm, sort, setSort, value, addFilter, clearFilters,  results, isLoading }) => ({
                searchTerm,
                setSearchTerm,
                sort,
                setSort,
                addFilter,
                clearFilters,
                value,
                results,
                isLoading
              })}
            >
              {({ searchTerm, setSearchTerm, sort, setSort, addFilter, clearFilters, results, isLoading }) => {
                return (
                  <div className="flex flex-col bg-white w-full mb-2 h-screen overflow-auto md:flex-row">
                    {/* LEFT BAR */}
                    <div className="h-screen overflow-y-auto px-3 bg-theme-blue"> </div>
                    {/* SEARCH BAR */}
                    <div className="px-5 py-8 border-b border-theme-darkgray md:w-5/12 md:border-r md:min-h-screen md:overflow-y-auto">
                      <h1 className="font-regular font-medium text-3xl">Search Minutes</h1>
                      <input class="mt-5 py-4 px-3 w-full border border-black leading-tight font-regular font-thin placeholder-s placeholder-black"
                        placeholder="Search"
                        autoFocus
                        value={this.state.search}
                        onChange={e => this.setState({search: e.target.value})}
                        onKeyUp={e => { 
                          if (e.key === 'Enter') {
                            clearFilters()
                            setSort(this.state.sort[0], this.state.sort[1])
                            this.state.local.map(selectedLocality => {
                              return addFilter('locality', selectedLocality, 'any')
                            })
                            this.state.state.map(selectedState => {
                              return addFilter('state', selectedState, 'any')
                            })
                            setSearchTerm(this.state.search, { shouldClearFilters: false })

                            // working within the app search framework is a little finnicky, not using a hook
                            ReactGA.event({
                              category: "search",
                              action: "submit-search",
                              label: "submit-search-search_page",
                            });

                          }
                        }}
                      />
                      <div className="mt-1 font-regular font-light text-sm">
                        For multiple keywords, separate them by comma<br />
                        Showing { results.length } {(results.length !== 1) ? (' results') : (' result')}
                      </div>
                      <Accordion allowZeroExpanded className="my-2">
                        <AccordionItem className="mb-2">
                          <AccordionItemHeading className="pb-5 border-b-4">
                              <AccordionItemButton className="text-theme-darkblue font-regular text-m">
                                <div className="flex justify-between items-center">
                                <div className="ml-2">Filter/Sort Results</div>
                                <AccordionItemState>
                                  {({ expanded }) => (expanded ?
                                    <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                                    </svg>
                                    :
                                    <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                                    </svg>
                                  )}
                                </AccordionItemState>
                                </div>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="mt-10">
                            {/* SORT PICKER */}
                            <div className="mt-10">
                              <div className="mb-2 font-regular font-light text-theme-darkgray text-sm">SORT BY</div>
                              <Select
                                styles={dropdownStyle}
                                className="font-regular text-lg"
                                //value={{label: this.state.sortLabel, value: this.state.sort}}
                                value={
                                  this.state.sort.length > 0
                                    ? { label: this.state.sortLabel, value: this.state.sort }
                                    : false
                                }
                                //onChange={e => this.setState({sort: e.value, sortLabel: e.label})}
                                onChange={(selectedOption) => {
                                  if (!selectedOption || !selectedOption.value) {
                                    this.setState({ sort: undefined, sortLabel: undefined });
                                  } else {
                                    this.setState({ sort: selectedOption.value, sortLabel: selectedOption.label });
                                  }
                                }}
                              
                                options={sortOptions}
                                placeholder="Any"
                              />
                            </div>
                            {/* LOCALITY FILTER */}
                            <div className="mt-5">
                              <div className="mb-2 font-regular font-light text-theme-darkgray text-sm">LOCALITY</div>
                              <Select
                                isMulti
                                styles={dropdownStyle}
                                className="font-regular text-lg"
                                onChange={ this.handleLocalChange }
                                value={this.state.localityOptions.filter((obj) => this.state.local.includes(obj.value))}
                                options={this.state.localityOptions}
                                placeholder="Any Locality"
                              />
                            </div>
                            {/* STATE FILTER */}
                            <div className="mt-5">
                              <div className="mb-2 font-regular font-light text-theme-darkgray text-sm">STATE</div>
                              <Select
                                isMulti
                                styles={dropdownStyle}
                                className="font-regular text-lg"
                                onChange={ this.handleStateChange }
                                value={this.state.stateOptions.filter((obj) => this.state.state.includes(obj.value))}
                                options={this.state.stateOptions}
                                placeholder="Any State"
                              />
                            </div>
                            <div className="font-regular flex justify-end">
                              <button
                                className="mt-2 font-regular text-s text-theme-hyperlink"
                                onClick={() => { 
                                                clearFilters()
                                                setSearchTerm("", { shouldClearFilters: true })
                                                setSort(null)
                                                this.setState({search: "", sort: [], sortLabel: "", state: [], local: []})
                                              }
                                }>
                                <u>Clear all</u>
                              </button>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                      <div className="flex flex-col mt-1">
                      <div className="w-full">
                          <button 
                            className={`mt-5 flex w-full items-center justify-center border border-transparent bg-gray-600 py-3 px-8 text-base font-medium text-white`}
                            onClick={() => { 
                              clearFilters()
                              setSort(this.state.sort[0], this.state.sort[1])
                              this.state.local.map(selectedLocality => {
                              return addFilter('locality', selectedLocality, 'any')})
                              this.state.state.map(selectedState => {
                                return addFilter('state', selectedState, 'any')})
                              setSearchTerm(this.state.search, { shouldClearFilters: false })
                              
                              // working within the app search framework is a little finnicky, not using a hook
                              ReactGA.event({
                                category: "search",
                                action: "click-search",
                                label: "click-search-search_page",
                              });
                            }
                          }>
                              Search
                          </button>
                        </div>
                        <div className="w-full">
                          <SignUpButton searchItem={this.state.search} local={this.state.local} state={this.state.state} setToast={this.handleToast} onClick={this.handleAddAlertClick}/>
                        </div>
                      </div>
                      {this.state.toast.state && <Toast notified={this.state.toast.notified} setToast={this.handleToast}/>}
                    </div>
                    {/* RESULTS CONTAINER */}
                    <div className="overflow-y-auto min-h-screen bg-theme-gray bg-opacity-50 md:w-7/12">
                      {/* LATEST MEETINGS SECTION */}
                      {!isLoading && results.length !== 0 && (
                        <LatestMeetings 
                          resultdata={results} 
                          month_names={month_names}
                          searchTerm={searchTerm}
                          drawer={this.state.drawer}
                          onResultClick={this.findDrawer}
                          onDrawerClose={this.closeDrawer}>
                        </LatestMeetings>
                      )}

                      {/* SEARCH RESULTS SECTION */}
                      {isLoading ? (
                        <div className="flex items-center justify-center h-64">
                          <div className="flex flex-col items-center">
                            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-theme-blue"></div>
                            <span className="mt-4 text-gray-600 text-lg">Getting results...</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="mt-4 mx-2 font-regular font-light text-sm">SEARCH RESULTS</div>
                          {results.length === 0 ? (
                            <div className="mx-9 mt-20 flex flex-col items-center justify-center">
                              <svg t="1681275030302" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12690" width="200" height="200">
                                {/* ... existing SVG code ... */}
                              </svg>
                              <h2 className="mt-5 text-gray-800 text-xl font-bold">Sorry! No results found</h2>
                              <p className="mt-3 text-gray-600">Please try searching for something different or broaden your filters</p>
                            </div>
                          ) : (
                            <div className="search-results">
                              {results.map(r => {
                                if ('transcript' in r) {
                                  return (
                                    <div className="px-2 py-2 mx-2 my-2 border border-theme-darkgray/50 bg-white" key={r.id}>
                                      {/* CARD TITLE */}
                                      <div className="font-regular font-medium text-xl">
                                        {r.locality.raw.replaceAll("_"," ").replaceAll(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, {r.state.raw.toUpperCase()} - {month_names[+r.upload_date.raw.substring(5,7)-1]} {+r.upload_date.raw.substring(8,10)}, {+r.upload_date.raw.substring(0,4)}
                                      </div>
                                      {/* CARD SUBTITLE */}
                                      <div className="font-regular font-medium text-l text-theme-darkgray">
                                        {r.video_title.raw.replaceAll('"','')}
                                      </div>
                                      {/* TRANSCRIPT BODY */}
                                      <div className="mt-3 border-l-4 border-theme-lightblue font-regular font-light text-l">
                                        {r.transcript.raw === '0' ? (
                                          <div className="ml-5">
                                            <i>Transcript unavailable.</i>
                                          </div>
                                        ) : r.segment.raw !== '0' ? (
                                          <div className="ml-5">
                                            <Highlighter
                                              searchWords={searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                              autoEscape={true}
                                              textToHighlight={"..." + r.transcript.raw.replaceAll('"','').replaceAll('&nbsp','')}
                                            />
                                          </div>
                                        ) : (
                                          <div className="ml-5">
                                            <Highlighter
                                              searchWords={searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                              autoEscape={true}
                                              textToHighlight={r.transcript.raw.charAt(0).replaceAll('"','').replaceAll('&nbsp','').toUpperCase() + r.transcript.raw.slice(1).replaceAll('"','').replaceAll('&nbsp','')}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {/* CARD FOOTER */}
                                      <div className="mt-3">
                                        <div className="grid grid-cols-2">
                                          <div className="float-left text-left font-regular font-light text-l">
                                            {r.segment_start.raw} / {r.video_length.raw}
                                          </div>
                                          <div>
                                            <div className="float-right font-regular flex">
                                              <button
                                                className="ml-2 font-regular text-s text-theme-hyperlink"
                                                onClick={() => this.findDrawer(r)}>
                                                <u>View More</u>
                                              </button>
                                              <ResultDetail 
                                                resultdata={r} 
                                                drawer={this.state.drawer}
                                                closeDrawer={this.closeDrawer} 
                                                month_names={month_names}
                                                searchTerm={searchTerm}>
                                              </ResultDetail>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                                return null;
                              })}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              }}
            </WithSearch>
          </SearchProvider>
          </div>
        </div>
      )
    };
  }

export default Search;
