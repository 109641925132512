import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { getCookie } from "../../getCookie";
import About from "../About/About";
import Contact from "../Contact/Contact";
import MultiContact from "../Contact/MultiContact";
import Homepage from "../Homepage/Homepage";
import Login from "../Login/Login";
import Search from "../Search/Search";
// import TestLandingPage from "../Search/TestLandingPage";
import Notification from "../Notification/Notification";
// for activity tracking
import ReactGA from 'react-ga4'


function getToken() {
  const userToken = localStorage.getItem('token');

  return userToken
}

async function checkToken(userToken) {
  return fetch('/token-check/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "token": userToken })
  })
  .then(data => data.json())
}

async function getUserProfile() {
  return fetch("/user_auth/profiles/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
      Authorization: "Token " + localStorage.getItem("auth-token"),
    },
  })
  .then(data => data.json())
}

function checkLogin(response) {
  let currentUser = null
  let isLoggedIn = false

  try {
    currentUser = response.user.username
    isLoggedIn = true

    return [currentUser, isLoggedIn]
  } catch (e) {
    return [currentUser, isLoggedIn]
  }
}

function initializeAnalytics() {
  let GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
  ReactGA.initialize(GA_TRACKING_ID);

  return true
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      token: '',
      username: '',
      userId: 0,
    }
  }

  async componentDidMount() {
    // get a token from local storage and submit
    const userToken = getToken()
    const response = await checkToken(userToken)

    // check the token for a valid login
    const checkedData = checkLogin(response)
    const currentUser = checkedData[0]
    const isLoggedIn = checkedData[1]

    // if logged in, fetch user_id from profile.user
    let currentUserId = 0
    if (isLoggedIn) {
      // this has to be a separate call because we use the built-in rest_framework_jwt.views.verify_jwt_token,
      // which does not have access to the profile.user attribute
      const profile = await getUserProfile()
      currentUserId = profile.user
    }

    // initialize GA session, subsequent pages only need to fire events
    initializeAnalytics();
    ReactGA.set({ userId: currentUserId });

    this.setState({
      loggedIn: isLoggedIn,
      token: userToken,
      username: currentUser,
      user_id: currentUserId,
    })
  }

  // follow user_id prop and update GA userId as needed
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user_id !== this.props.user_id) {
      ReactGA.set({ userId: this.props.user_id });
    }
  }

  render() {
    if(!this.state.loggedIn && !(
        window.location.pathname === "/"
        || window.location.pathname === "/about"
        || window.location.pathname === "/contact"
        || window.location.pathname === "/login"
    )) {
      return (
        <Login loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} path={window.location.pathname}/>
      )
    }

    return (
      <div>
        <Router>
         <Route exact path="/" component={() => <Homepage loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} />} />
         <Route exact path="/about" component={() => <About loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} />} />
         <Route exact path="/contact" component={() => <MultiContact loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} />} />
         <Route exact path="/login" component={() => <Login loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} path={window.location.pathname} />} />
         <Switch>
              <Route exact path="/search" component={() => <Search loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} />} />
         </Switch>
         <Route exact path="/settings" component={() => <Notification loggedIn={this.state.loggedIn} username={this.state.username} user_id={this.state.user_id} />} />
        </Router>
      </div>
    )
  }
}

export default App;
