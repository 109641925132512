import { useEffect, useState } from "react";
import { getCookie } from "../../getCookie";

const SignUpButton = ({ searchItem, local, state, setToast }) => {
  const [success, setSuccess] = useState(false);

  const addKeywords = async () => {
    const keywords = searchItem.includes(",")
      ? searchItem.replace(/\s/g, "").split(",")
      : [searchItem];
    fetch("/user_auth/alerts/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
        Authorization: "Token " + localStorage.getItem("auth-token"),
      },
      body: JSON.stringify({
        keywords: keywords,
        user: localStorage.getItem("token"),
        locality: local.length ? local : [""],
        state: state.length ? state : [""],
        is_notifying: true,
        channel: "",
        frequency: "",
        day: "",
        time: "",
      }),
    }).then((data) => {
      setSuccess(true);
      if (data.status === 201) {
        setToast({ notified: "alert success", state: true });
      } else {
        setToast({ notified: "alert error", state: true });
      }
    });
  };

  useEffect(() => {
    setSuccess(false);
  }, [searchItem, local, state]);


  return (
    <div className="mt-5">
      {(success || !searchItem) ? (
          <button
            className="mt-5 flex w-full items-center justify-center border border-transparent bg-blue-300 py-3 px-8 text-base font-medium text-white"
            disabled
          >
            Add Alert
          </button>
      ) : (
        <button
          className="mt-5 flex w-full items-center justify-center border border-transparent bg-blue-500 py-3 px-8 text-base font-medium text-white"
          onClick={addKeywords}
          disabled={!searchItem} 
        >
          Add Alert
        </button>
      )}
    </div>
  );
};

export default SignUpButton;
