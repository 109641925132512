import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import Transcript from "./Transcript";
import Drawer from 'react-modern-drawer';

// Import drawer styles BEFORE our custom styles
import 'react-modern-drawer/dist/index.css';
import '../../index.css';

const ResultDetail = ({ resultdata, drawer, closeDrawer, month_names, searchTerm}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [meetingSummary, setMeetingSummary] = useState(null);

  // Handle mounting/unmounting
  useEffect(() => {
    let mounted = true;

    const handleMount = async () => {
      if (!mounted) return;
      
      setIsLoading(true);
      setIsTransitioning(true);

      // Debug log the resultdata structure
      console.log('ResultDetail handleMount - resultdata:', resultdata);

      // Fetch summary if log file path exists
      const logPath = resultdata?.['log.file.path']?.raw;
      if (logPath) {
        try {
          const identifier = logPath.split('/').pop().replace('.csv', '');
          console.log('Fetching summary for identifier:', identifier);
          const response = await fetch(`/user_auth/summary/${identifier}`, {
            headers: {
              "Authorization": "Token " + localStorage.getItem("auth-token"),
              "Content-Type": "application/json"
            }
          });
          if (response.ok) {
            const summaryData = await response.json();
            console.log('Meeting Summary:', summaryData);
            if (mounted) {
              setMeetingSummary(summaryData.meeting);
            }
          }
        } catch (error) {
          console.error('Error fetching summary:', error);
        }
      } else {
        console.log('No log file path found in resultdata:', resultdata);
      }

      // Simulate waiting for resources to load
      await new Promise(resolve => setTimeout(resolve, 100));
      
      if (!mounted) return;
      setIsLoading(false);
      setIsTransitioning(false);
    };

    if (drawer.isOpen && drawer.currentVideo === resultdata?.video_url?.raw) {
      handleMount();
    }

    // Cleanup function
    return () => {
      mounted = false;
      setIsTransitioning(false);
      setIsLoading(true);
      setMeetingSummary(null);
    };
  }, [drawer.isOpen, drawer.currentVideo, resultdata?.video_url?.raw]);

  // Handle drawer close with cleanup
  const handleClose = useCallback(() => {
    setIsTransitioning(true);
    closeDrawer();
  }, [closeDrawer]);

  console.log('ResultDetail render:', {
    videoUrl: resultdata?.video_url?.raw,
    segment: resultdata?.segment_start?.raw,
    drawer,
    isTransitioning,
    isLoading,
    shouldShow: drawer.currentVideo === resultdata?.video_url?.raw
  });

  // Only render when this is the current result
  if (drawer.currentVideo !== resultdata?.video_url?.raw) {
    console.log('ResultDetail early return - no match');
    return null;
  }

  return (
    <>
      <Drawer 
        size={typeof window !== 'undefined' && window.innerWidth < 640 ? "100%" : "90vw"}
        direction="right"
        open={drawer.isOpen}
        onClose={handleClose}
        duration={300}
        lockBackgroundScroll={true}
        overlayOpacity={0.4}
        className="bg-white"
        enableOverlay={true}>
        <div className="h-full flex flex-row justify-between max-sm:block">
          {/* Left blue bar - desktop only */}
          <div className="w-1/12 hidden sm:block">
            <div className="w-1/6 h-full bg-theme-blue"></div>
          </div>
          
          {/* Main content */}
          <div className="flex flex-col w-full sm:w-10/12 bg-white">
            <div className="px-4 py-4">
              <button type="button"
                      className="mr-1 text-black-700 border border-black hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                      onClick={handleClose}
                      disabled={isTransitioning}>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" transform="scale(-1, 1)"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Back to search results</span>
              </button>
            </div>
            <section className="flex-1 px-4 sm:px-10 overflow-y-scroll">
              <Transcript 
                resultdata={resultdata} 
                searchTerm={searchTerm}
                targetSegment={drawer.currentSegment}
                meetingSummary={meetingSummary}
                isLoading={isLoading} />
            </section>
          </div>
        </div>
      </Drawer>
    </>
  );
};

ResultDetail.propTypes = {
  resultdata: PropTypes.shape({
    video_url: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    segment_start: PropTypes.shape({
      raw: PropTypes.string
    }),
    title: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    transcript: PropTypes.string,
  }).isRequired,
  drawer: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    currentVideo: PropTypes.string,
    currentSegment: PropTypes.string
  }).isRequired,
  closeDrawer: PropTypes.func.isRequired,
  month_names: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchTerm: PropTypes.string
};

export default ResultDetail;