// base react
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types';
import * as ElasticAppSearch from "@elastic/app-search-javascript";
import TranscriptErrorBoundary from './TranscriptErrorBoundary';

// helper functions to configure search
import { getConfig } from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';

// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const client = ElasticAppSearch.createClient({
    hostIdentifier,
    searchKey,
    engineName,
    endpointBase,
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getVideoType(url) {
  if (url.includes('youtube')) return 'youtube';
  if (url.includes('vimeo')) return 'vimeo';
  return null;
}

function formatTimestampUrl(url, timestamp, videoType) {
  if (!videoType) return url;

  const split_time = timestamp.split(':');
  const seconds = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);

  if (videoType === 'youtube') {
    return url;
  } else if (videoType === 'vimeo') {
    const vimeoMatch = url.match(/(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i);
    if (vimeoMatch) {
      return `https://vimeo.com/${vimeoMatch[1]}#t=${seconds}s`;
    }
  }
  return url;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

function getId(url) {
  // Handle YouTube IDs
  const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const youtubeMatch = url.match(youtubeRegExp);
  if (youtubeMatch && youtubeMatch[2].length === 11) {
      return { platform: 'youtube', id: youtubeMatch[2] };
  }

  // Handle Vimeo IDs
  const vimeoRegExp = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
  const vimeoMatch = url.match(vimeoRegExp);
  if (vimeoMatch) {
      return { platform: 'vimeo', id: vimeoMatch[1] };
  }

  return null;
}

function convertTimestampToSeconds(timestamp) {
  const split_time = timestamp.split(':');
  return (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);
}

const Transcript = (props) => {
  const [allTranscript, setAllTranscript] = useState([])
  const [selectedRange, setSelectedRange] = useState({ start: null, end: null })
  const [isSelecting, setIsSelecting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [embedId, setEmbedId] = useState(null)
  const [copyFeedback, setCopyFeedback] = useState(false)
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const [error, setError] = useState(null)
  const [embedError, setEmbedError] = useState(null)
  const scrollTargetRef = useRef(null);
  const iframeRef = useRef(null);
  const hasScrolledRef = useRef(false);
  const scrollContainerRef = useRef(null);

  // Reset all state when video URL changes
  useEffect(() => {
    hasScrolledRef.current = false;
    setIsIframeLoading(true);
    setError(null);
    setEmbedError(null);
    setSelectedRange({ start: null, end: null });
    setIsSelecting(false);
  }, [props.resultdata.video_url.raw]);

  // Memoize event handlers to prevent unnecessary re-creation
  const handleEsc = React.useCallback((event) => {
    if (event.key === 'Escape') {
      setShowModal(false);
    }
  }, []); // No dependencies since setShowModal is stable

  // Cleanup for escape key listener
  useEffect(() => {
    if (showModal) {
      window.addEventListener('keydown', handleEsc);
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [showModal, handleEsc]);

  // Cleanup for iframe message listener
  useEffect(() => {
    const handleIframeMessage = (event) => {
      // Only handle messages from our iframe
      if (iframeRef.current && event.source === iframeRef.current.contentWindow) {
        // Handle any iframe messages here
        console.log('Received message from iframe:', event.data);
      }
    };

    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  // Load transcript when video URL changes
  useEffect(() => {
    let isCancelled = false;
    const controller = new AbortController();

    const loadTranscript = async () => {
      const videoUrl = props.resultdata.video_url.raw;
      console.log('Loading transcript for video:', {
        url: videoUrl,
        targetSegment: props.resultdata.segment_start?.raw
      });

      const options = {
        filters: { video_url: videoUrl },
        sort: { "segment_start": "asc" },
        result_fields: { transcript: { raw: {} }, timestamp_url: { raw: {} }, segment_start: { raw: {} } },
        page: { size: 500 }
      };

      try {
        setError(null);
        const resultList = await client.search("", options);
        
        // Only update state if the component is still mounted and this is the latest request
        if (!isCancelled) {
          const allTrans = resultList.results.map(result => ({
            transcript: result.getRaw("transcript"),
            timestamp: result.getRaw("segment_start"),
            url: result.getRaw("timestamp_url")
          }));
          setAllTranscript(allTrans);
        }
      } catch (error) {
        // Only update error state if the component is still mounted and this isn't a cancelled request
        if (!isCancelled && error.name !== 'AbortError') {
          console.error('Error loading transcript:', error);
          setError('Failed to load transcript. Please try again later.');
          setAllTranscript([]);
        }
      }
    };

    setAllTranscript([]); // Clear transcript when URL changes
    loadTranscript();

    // Cleanup function to handle unmounting or URL changes
    return () => {
      isCancelled = true;
      controller.abort();
    };
  }, [props.resultdata.video_url.raw]);

  // Function to handle scrolling to segment
  const scrollToSegment = useCallback((segmentTimestamp) => {
    if (!allTranscript.length) {
      console.log('Transcript not loaded yet, skipping scroll');
      return;
    }

    const segmentIndex = allTranscript.findIndex(segment => 
      segment.timestamp === segmentTimestamp
    );

    if (segmentIndex === -1) return;

    // Get scroll container from ref instead of query selector
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      console.log('Scroll container not found, skipping scroll');
      return;
    }

    // Cancel any existing scroll animations
    scrollContainer.style.scrollBehavior = 'auto';
    scrollContainer.scrollTop = scrollContainer.scrollTop;

    // Store the animation frame ID so we can cancel it if needed
    const animationFrameId = requestAnimationFrame(() => {
      const element = document.getElementById(`transcript-segment-${segmentIndex}`);
      
      if (element && scrollContainer) {
        scrollContainer.style.scrollBehavior = 'smooth';
        const scrollTop = element.offsetTop - 100;
        scrollContainer.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        });
      }
    });

    // Return cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
      if (scrollContainer) {
        scrollContainer.style.scrollBehavior = 'auto';
      }
    };
  }, [allTranscript]);

  // Handle initial scroll when transcript loads
  useEffect(() => {
    // Reset scroll state when transcript changes
    hasScrolledRef.current = false;

    if (!allTranscript.length || !props.targetSegment) {
      return;
    }

    let timeoutId;
    let cleanupScroll;

    const performScroll = () => {
      if (!hasScrolledRef.current) {
        cleanupScroll = scrollToSegment(props.targetSegment);
        hasScrolledRef.current = true;
      }
    };

    // First attempt immediate scroll
    performScroll();

    // Backup scroll after a delay in case the first attempt didn't work
    timeoutId = setTimeout(performScroll, 100);

    return () => {
      clearTimeout(timeoutId);
      if (cleanupScroll) {
        cleanupScroll();
      }
    };
  }, [allTranscript, props.targetSegment, scrollToSegment]);

  const handleIframeLoad = useCallback(() => {
    setIsIframeLoading(false);
  }, []);

  const handleCheckboxChange = (index) => {
    if (!isSelecting) {
      // Start new selection
      setIsSelecting(true);
      setSelectedRange({ start: index, end: index });
    } else {
      // Update existing selection
      setIsSelecting(false);
      if (index < selectedRange.start) {
        setSelectedRange({ ...selectedRange, end: selectedRange.start, start: index });
      } else {
        setSelectedRange({ ...selectedRange, end: index });
      }
    }
  };

  const createEmbed = async () => {
    if (!selectedRange.start && !selectedRange.end) return;

    try {
      setEmbedError(null);
      // Get the selected segments from our existing transcript data
      const selectedSegments = allTranscript.slice(
        selectedRange.start,
        selectedRange.end + 1
      ).map(segment => ({
        timestamp: segment.timestamp,
        text: segment.transcript
      }));

      // Get the video URL from the result data
      const videoUrl = props.resultdata.video_url?.raw || 
                      props.resultdata.timestamp_url?.raw?.split('&t=')[0];

      const response = await fetch('/user_auth/embed/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
          'Authorization': `Token ${localStorage.getItem('auth-token')}`
        },
        body: JSON.stringify({
          video_url: videoUrl,
          start_time: allTranscript[selectedRange.start].timestamp,
          end_time: selectedRange.end < allTranscript.length - 1 
            ? allTranscript[selectedRange.end + 1].timestamp 
            : null,
          segments: selectedSegments
        })
      });

      const data = await response.json();
      if (response.ok) {
        setEmbedId(data.id);
        setShowModal(true);
        setSelectedRange({ start: null, end: null });
      } else {
        throw new Error(data.error || 'Failed to create embed');
      }
    } catch (error) {
      console.error('Error creating embed:', error);
      setEmbedError(error.message || 'Failed to create embed. Please try again.');
    }
  };

  const getEmbedUrl = () => {
    const link = props.resultdata.timestamp_url.raw;
    const video = getId(link);
    let embedUrl = '';
    
    if (video) {
      if (video.platform === 'youtube') {
        // Only use start time if it's a search result (props.targetSegment is present)
        if (props.targetSegment) {
          const split_time = props.targetSegment.split(':');
          const start = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);
          console.log('Initializing YouTube iframe with start time:', props.targetSegment, start);
          embedUrl = `https://www.youtube.com/embed/${video.id}?start=${start}&enablejsapi=1&origin=${window.location.origin}&playsinline=1`;
        } else {
          // For latest meetings, start from beginning
          console.log('Latest meeting - starting from beginning');
          embedUrl = `https://www.youtube.com/embed/${video.id}?enablejsapi=1&origin=${window.location.origin}&playsinline=1`;
        }
      } else if (video.platform === 'vimeo') {
        // Only use start time if it's a search result
        if (props.targetSegment) {
          const split_time = props.targetSegment.split(':');
          const start = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);
          embedUrl = `https://player.vimeo.com/video/${video.id}#t=${start}s`;
        } else {
          // For latest meetings, start from beginning
          embedUrl = `https://player.vimeo.com/video/${video.id}`;
        }
      }
    }
    return embedUrl;
  };

  const handleTimestampClick = (e, timestamp) => {
    e.preventDefault();
    const iframe = iframeRef.current;
    if (!iframe) return;

    // Scroll to the clicked timestamp
    scrollToSegment(timestamp);

    const seconds = convertTimestampToSeconds(timestamp);
    const video = getId(props.resultdata.timestamp_url.raw);
    
    if (video?.platform === 'youtube') {
      // YouTube postMessage API
      console.log('Sending seekTo command for timestamp:', timestamp, 'seconds:', seconds);
      
      // First, ensure the player is ready
      iframe.contentWindow.postMessage(JSON.stringify({
        event: 'command',
        func: 'seekTo',
        args: [seconds]
      }), '*');

      // Send play command with a small delay to ensure seek completes
      setTimeout(() => {
        console.log('Sending playVideo command after seek');
        iframe.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'playVideo',
          args: []
        }), '*');
      }, 100); // Increased delay to ensure seek completes
    } else if (video?.platform === 'vimeo') {
      // Vimeo postMessage API
      iframe.contentWindow.postMessage({
        method: 'seekTo',
        value: seconds
      }, '*');
    }
  };

  return (
    <div className="Transcript h-full flex flex-col">
      { error ? (
        <div className="p-4 text-red-700 bg-red-100 rounded-md">
          <p className="font-medium">Error</p>
          <p>{error}</p>
        </div>
      ) : allTranscript.length === 0 ? (
        <div>Loading transcript...</div>
      ) : (
        <>
          {/* Fixed top section */}
          <div className="grid grid-cols-12 gap-8 mb-4">
            {/* Upper Left: Video Metadata (4 columns) */}
            <div className="col-span-4">
              <h2 className="text-theme-black font-regular font-bold text-2xl mb-2">{props.resultdata.video_title.raw.replaceAll('"','')}</h2>
              <h3 className="text-gray-600 text-xl mb-2">{props.resultdata.locality.raw.replaceAll("_"," ").replaceAll(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, {props.resultdata.state.raw.toUpperCase()}</h3>
              <p className="text-gray-600">Uploaded on {formatDate(props.resultdata.upload_date.raw)}</p>
              <p className="text-gray-600">Total time: {props.resultdata.video_length.raw}</p>
            </div>

            {/* Upper Right: Video Embed (8 columns) */}
            <div className="col-span-8 -mt-8">
              <div className="aspect-w-16 aspect-h-9 relative">
                { getId(props.resultdata.timestamp_url.raw) && (
                  <>
                    {isIframeLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                        <div className="flex flex-col items-center">
                          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-theme-blue"></div>
                          <span className="mt-2 text-gray-600">Loading video...</span>
                        </div>
                      </div>
                    )}
                    <iframe 
                      ref={iframeRef}
                      id="youtube-player"
                      title="video-embed" 
                      className="w-full" 
                      style={{
                        height: "calc(50vw * 9/16)",
                        opacity: isIframeLoading ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out'
                      }}
                      src={getEmbedUrl()} 
                      frameBorder="0" 
                      allow="autoplay"
                      allowFullScreen
                      onLoad={handleIframeLoad}
                    ></iframe>
                  </>
                )}
              </div>
            </div>

            {/* Show embed error if exists */}
            {embedError && (
              <div className="col-span-12">
                <div className="p-4 text-red-700 bg-red-100 rounded-md mb-4">
                  <p>{embedError}</p>
                </div>
              </div>
            )}
          </div>

          {/* Scrollable bottom section */}
          <div ref={scrollContainerRef} className="flex-1 overflow-auto min-h-0 pb-4">
            <div className="grid grid-cols-12 gap-0">
              {/* Lower Left: Transcript Summary (4 columns) */}
              <div className="col-span-4 border-2 border-theme-blue/50 p-4">
                <h3 className="text-lg font-semibold mb-2">Summary</h3>
                <p className="text-gray-600">Summary functionality coming soon...</p>
              </div>

              {/* Lower Right: Full Transcript (8 columns) */}
              <div className="col-span-8 border-2 border-theme-blue/50 border-l-0 p-4">
                {selectedRange.start !== null && (
                  <div className="mb-4 flex justify-between items-center">
                    <div className="text-gray-600">
                      Selected range: {allTranscript[selectedRange.start].timestamp} - {
                        selectedRange.end < allTranscript.length - 1 
                          ? allTranscript[selectedRange.end + 1].timestamp 
                          : allTranscript[allTranscript.length - 1].timestamp
                      }
                    </div>
                    <button
                      onClick={createEmbed}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      Create Embed
                    </button>
                  </div>
                )}
                <div className="relative overflow-x-auto">
                  <table className="w-full table-auto text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 align-top">Timestamp</th>
                        <th scope="col" className="px-6 py-3 align-top">Transcript</th>
                        <th scope="col" className="px-4 py-3 align-top">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      { allTranscript.map((item, index) => {
                        const isSelected = selectedRange.start !== null && 
                          index >= Math.min(selectedRange.start, selectedRange.end) && 
                          index <= Math.max(selectedRange.start, selectedRange.end)

                        return (
                          <tr 
                            key={index}
                            id={`transcript-segment-${index}`} 
                            className={`bg-white border-b ${isSelected ? 'bg-blue-50' : ''}`}
                          >
                            <td className="px-6 py-4 align-top">
                              <button 
                                onClick={(e) => handleTimestampClick(e, item.timestamp)}
                                className="font-regular text-s text-theme-hyperlink hover:underline"
                              >
                                {item.timestamp}
                              </button>
                            </td>
                            { item.transcript === '0' ? (
                              <td className="px-6 py-4 align-top">no speech</td>
                              ):(
                                <td className="px-6 py-4 align-top">
                                  <Highlighter
                                    searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                    autoEscape={true}
                                    textToHighlight={item.transcript}
                                  />
                                </td>
                              )
                            }
                            <td className="px-4 py-4 text-right align-top">
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleCheckboxChange(index)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Embed Code
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md">
                  <code className="text-sm text-gray-800 dark:text-gray-200">
                    {`<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`}
                  </code>
                </div>
                <div className="mt-4 flex justify-end items-center gap-2">
                  {copyFeedback && (
                    <span className="text-green-600 dark:text-green-400 text-sm">
                      Copied to clipboard!
                    </span>
                  )}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`
                      )
                      setCopyFeedback(true)
                      setTimeout(() => setCopyFeedback(false), 2000) // Hide after 2 seconds
                    }}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Copy Code
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
};

Transcript.propTypes = {
  resultdata: PropTypes.shape({
    video_url: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    video_title: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    locality: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    state: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    upload_date: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    video_length: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    timestamp_url: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    segment_start: PropTypes.shape({
      raw: PropTypes.string
    })
  }).isRequired,
  searchTerm: PropTypes.string
};

// Wrap the component with the error boundary
const WrappedTranscript = (props) => (
  <TranscriptErrorBoundary>
    <Transcript {...props} />
  </TranscriptErrorBoundary>
);

export default WrappedTranscript; 