//a component to quickly see the most recent 

import React, { useState, useMemo } from "react";
import ResultDetail from "./ResultDetail";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import PropTypes from 'prop-types';

const LatestMeetings = ({ resultdata, drawer, onResultClick, onDrawerClose, month_names, searchTerm }) => {
    // Internal state
    const [isExpanded, setIsExpanded] = useState(true);
    const [error, setError] = useState(null);

    // Memoize filtered results
    const uniqueResults = useMemo(() => {
        if (!resultdata || resultdata.length === 0) return [];
        
        return resultdata.filter((result, index, self) =>
            index === self.findIndex((t) => (
                t.video_url.raw === result.video_url.raw
            ))
        ).slice(0, 10);
    }, [resultdata]);

    // Handle errors
    if (error) {
        return (
            <div className="my-2 mx-2 px-2 py-2 border border-theme-darkgray/50 bg-white">
                <p className="text-red-600">Error: {error}</p>
            </div>
        );
    }

    // Handle loading/empty state
    if (!uniqueResults.length) {
        return (
            <div className="my-2 mx-2 px-2 py-2 border border-theme-darkgray/50 bg-white">
                <p>Sorry can't fetch meetings right now.</p>
            </div>
        );
    }

    return (
        <div className="my-2 mx-2 px-2 py-2 border border-theme-darkgray/50 bg-white">
            <Accordion allowZeroExpanded preExpanded={isExpanded ? ['panel-1'] : []}>
                <AccordionItem uuid="panel-1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div className="flex justify-between items-center">
                                <span className="font-regular font-light text-sm">LATEST MEETINGS</span>
                                <div className="flex flex-row justify-end items-center text-theme-darkblue font-regular text-sm">
                                    <div className="text-right ml-2">Show/Hide</div>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            // Keep internal state in sync
                                            if (expanded !== isExpanded) {
                                                setIsExpanded(expanded);
                                            }
                                            return expanded ? (
                                                <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                                                </svg>
                                            ) : (
                                                <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                                                </svg>
                                            );
                                        }}
                                    </AccordionItemState>
                                </div>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="mt-5">
                        <div className="pb-1 font-regular font-light text-center text-sm">
                            <p>(Click on meeting for more detail)</p>
                        </div>
                        {uniqueResults.map(result => (
                            <div 
                                className="grid grid-cols-3 font-regular font-light text-m px-1 py-1 bg-opacity-50 hover:bg-theme-blue border border-transparent" 
                                key={result.video_url.raw}
                            >
                                <button 
                                    className="text-left font-regular"
                                    onClick={() => onResultClick(result, true)}
                                >
                                    <div>
                                        {result.locality.raw.replaceAll("_"," ").replaceAll(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, 
                                        {result.state.raw.toUpperCase()}
                                    </div>
                                </button>
                                <div className="col-span-2">
                                    <button
                                        className="text-left font-regular text-theme-darkgray"
                                        onClick={() => onResultClick(result, true)}
                                    >
                                        {result.video_title.raw.replaceAll('"','')}
                                    </button>
                                    <ResultDetail 
                                        resultdata={result} 
                                        drawer={drawer}
                                        closeDrawer={onDrawerClose} 
                                        month_names={month_names}
                                        searchTerm={searchTerm}
                                    />
                                </div>
                            </div>
                        ))}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

LatestMeetings.propTypes = {
    resultdata: PropTypes.arrayOf(PropTypes.shape({
        video_url: PropTypes.shape({
            raw: PropTypes.string.isRequired
        }).isRequired,
        locality: PropTypes.shape({
            raw: PropTypes.string.isRequired
        }).isRequired,
        state: PropTypes.shape({
            raw: PropTypes.string.isRequired
        }).isRequired,
        video_title: PropTypes.shape({
            raw: PropTypes.string.isRequired
        }).isRequired
    })).isRequired,
    drawer: PropTypes.shape({
        isOpen: PropTypes.bool.isRequired,
        currentVideo: PropTypes.string,
        currentSegment: PropTypes.string
    }).isRequired,
    onResultClick: PropTypes.func.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
    month_names: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchTerm: PropTypes.string
};

export default LatestMeetings;
