import React, { Component } from "react";
import Nav from '../Shared/Nav';
import '../../index.css';
import ReactGA from 'react-ga4';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: this.props.loggedIn,
      username: this.props.username,
      userId: this.props.user_id
    }
    
    this.handleSignupMain = this.handleSignupMain.bind(this);
    this.handleSignupFooter = this.handleSignupFooter.bind(this);
    this.handleContactFooter = this.handleContactFooter.bind(this);
  }

  handleSignupMain() {
    ReactGA.event({
      category: "signup",
      action: "click-signup-button",
      label: "click-signup-button-main-homepage",
    });
  }

  handleSignupFooter() {
    ReactGA.event({
      category: "signup",
      action: "click-signup-button",
      label: "click-signup-button-footer-homepage",
    });
  }

  handleContactFooter() {
    ReactGA.event({
      category: "signup",
      action: "click-signup-button",
      label: "click-signup-button-footer-homepage",
    });
  }

  render() {
    ReactGA.send({
      hitType: "pageview",
      page:"/",
      title:"home"
    });

    return (
      <div className="Home">
        {/* body */}
        <header className="sticky top-0 z-50">
          <Nav loggedIn={this.props.loggedIn} username={this.props.username} user_id={this.props.user_id} />
        </header>
        <main className="relative">
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-white">
            <div className="w-full grid-rows-2 md:flex items-center">
              <div className="md:w-1/2 md:mr-36 lg:pt-1/3">
                <p className="font-regular font-bold text-theme-darkblue text-4xl">An easier way to find the information you need on local government meetings</p>
                <p className="my-10 font-regular text-2xl ">Minutes automatically transcribes local government meetings into a searchable database</p>
                <a href="/contact" className="px-7 py-4 bg-theme-yellow align-middle font-regular font-semibold" onClick={this.handleSignupMain}><b>Sign up for the beta</b></a>
              </div>
              <div className="mt-14 relative flex justify-center md:w-1/3 md:float-right lg:pt-2/3">
                <img className="object-contain w-full max-w-xs h-full" src="https://storage.googleapis.com/minutes-resources/homepage_graphic.png" alt="..." />
              </div>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-blue">
            <div className="md:grid md:grid-cols-2 md:gap-10">
              <div className="flex flex-col">
                <img className="float-left w-20" src="https://storage.googleapis.com/minutes-resources/computer_icon.svg" alt="..." />
                <p className="mt-10 font-regular font-bold text-4xl text-black">Instant information on topics you care about</p>
                <p className="mt-5 font-regular text-l text-black">Local government meetings can stretch on for hours. Minutes allows you to keep tabs on the issues you care about, when you don’t have time for the full meeting.</p>
              </div>
              <div className="mt-10 md:mt-0 flex flex-col">
                <img className="float-left w-14 mb-2.5" src="https://storage.googleapis.com/minutes-resources/search_icon.svg" alt="..." />
                <p className="mt-10 font-regular font-bold text-4xl text-black">Filling a need in local newsrooms</p>
                <p className="mt-5 font-regular text-l text-black">At least 1,800 communities in the U.S. no longer have a local news source. Minutes is making it easier for residents and journalists to stay informed through its continuously-growing dataset of transcripts for thousands of local government meetings</p>
              </div>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-lightblue">
            <div className="flex flex-col">
              {/* <div className="pt-10 md:grid md:grid-cols-2 md:gap-10"> */}
                <p className="font-regular font-bold text-4xl text-black">Our partners</p>
                <div className="py-10 md:flex md:flex-row items-center md:px-5">
                  <div className="relative flex justify-center">
                    <img className="px-5 py-10 w-4/5 md:w-full" src="https://storage.googleapis.com/minutes-resources/gni-logo.svg" alt="Google News Initiative"/>
                  </div>  
                  <div className="relative flex justify-center"> 
                    <img className="w-3/5 md:w-4/5  px-5 py-10" src="https://storage.googleapis.com/minutes-resources/DDRP-NUMedill.png" alt="The KnightLab at Northwestern University"/>
                  </div>   
                  <div className="relative flex justify-center">
                    <img className="w-1/2 md:w-3/5  px-5 py-10" src="https://storage.googleapis.com/minutes-resources/Associated_Press_logo_2012.svg.png" alt="The Associated Press"/>
                  </div>  
                  <div className="relative flex justify-center">
                    <img className="w-3/5 md:w-full  px-5 py-10" src="https://storage.googleapis.com/minutes-resources/umsi-logo-vert-u.png" alt="The University of Michigan School of Information"/>
                  </div>
                </div>
                <div className="mt-5 flex flex-col md:mt-5 md:px-5">
                  <p className="font-regular text-l text-black">Minutes was created by journalists at Michigan Public, and grown with the help of many partners working together to make local, civic information easier to find.<br></br></p>
                  <a href="/about" className="font-regular font-semibold"><u>How it works →</u></a>
                </div>
              {/* </div> */}
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-white">
            <div>
              <p className="font-regular font-bold text-4xl text-black">Latest updates</p>
            </div>
            <div className="mt-10 md:grid md:grid-cols-3 md:gap-4">
              <div className="mt-5 border border-gray-300 flex flex-col justify-between md:mt-0">
                <div>
                  <p className="px-5 py-5 font-regular font-bold text-xl text-black">AP Local News AI Case Study: Minutes</p>
                  <p className="px-5 font-regular text-l text-black">AP</p>
                </div>
                <div className="px-5 py-5 flex flex-row">
                  <a href="https://www.ap.org/assets/files/ap-local-ai-michigan-radio-oct-2023.pdf" className="pr-2 font-regular font-medium text-theme-teal"><u>Read</u></a>
                  <img className="w-5" src="https://storage.googleapis.com/minutes-resources/long-arrow-right.svg" alt="..." />
                </div>
              </div>
              <div className="mt-5 border border-gray-300 flex flex-col justify-between md:mt-0">
                <div>
                  <p className="px-5 py-5 font-regular font-bold text-xl text-black">Michigan Radio’s new tool makes news buried in city council meetings easier to find</p>
                  <p className="px-5 font-regular text-l text-black">NiemanLab</p>
                </div>
                <div className="px-5 py-5 flex flex-row">
                  <a href="https://www.niemanlab.org/2021/03/michigan-radios-new-tool-makes-news-buried-in-city-council-meetings-easier-to-find/" className="pr-2 font-regular font-medium text-theme-teal"><u>Read</u></a>
                  <img className="w-5" src="https://storage.googleapis.com/minutes-resources/long-arrow-right.svg" alt="..." />
                </div>
              </div>
              <div className="mt-5 border border-gray-300 flex flex-col justify-between md:mt-0">
                <div>
                  <p className="px-5 py-5 font-regular font-bold text-xl text-black">Newsrooms answer the call to innovate</p>
                  <p className="px-5 font-regular text-l text-black">Editor & Publisher</p>
                </div>
                <div className="px-5 py-5 flex flex-row">
                  <a href="https://www.editorandpublisher.com/stories/newsrooms-answer-the-call-to-innovate,192713?newsletter=192735" className="pr-2 font-regular font-medium text-theme-teal"><u>Read</u></a>
                  <img className="w-5" src="https://storage.googleapis.com/minutes-resources/long-arrow-right.svg" alt="..." />
                </div>
              </div>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-yellow">
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-align-center font-regular font-bold text-4xl text-center">Sign up for the beta</h1>
              <h2 className="my-5 font-regular text-xl text-center">We are currently beta-testing Minutes with a select group of users. Contact us to get started.</h2>
              <a href="/contact" className="px-7 py-3 bg-theme-orange align-middle font-regular font-semibold text-2xl" onClick={this.handleSignupFooter}>Sign up</a>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-gray">
            <div className="grid grid-rows-2">
              <div>
                <a className="pr-5 font-regular font-medium" href="mailto:minutes@michiganradio.org?subject=Minutes%20beta%20tester%20signup" onClick={this.handleContactFooter}>Contact Us</a>
                <div className="mt-5 flex lg:float-right lg:mt-0">
                  <a className="font-regular font-medium lg:px-5" href="https://michiganradio.secureallegiance.com/wuom/WebModule/Donate.aspx?P=SUSTAINER&PAGETYPE=PLG&CHECK=7xZmO7zqt1vzhRN6l0Ol+61gzMC6uhq5nDjkJobrCdg%3D"><u>Donate</u></a>
                  <img className="px-5" src="https://storage.googleapis.com/minutes-resources/facebook-square.svg" alt="..."></img>
                  <img className="px-5" src="https://storage.googleapis.com/minutes-resources/linkedin.svg" alt="..."></img>
                  <img className="pl-5" src="https://storage.googleapis.com/minutes-resources/twitter.svg" alt="..."></img>
                </div>
              </div>
              <div className="pt-5 font-regular">
                ©2022 Minutes. All rights reserved. Minutes is supported by Michigan Public, an NPR Member Station and a public media service of The University of Michigan.
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Homepage;
