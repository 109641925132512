// base react
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types';
import * as ElasticAppSearch from "@elastic/app-search-javascript";
import TranscriptErrorBoundary from './TranscriptErrorBoundary';

// helper functions to configure search
import { getConfig } from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';

// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const client = ElasticAppSearch.createClient({
    hostIdentifier,
    searchKey,
    engineName,
    endpointBase,
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getVideoType(url) {
  if (url.includes('youtube')) return 'youtube';
  if (url.includes('vimeo')) return 'vimeo';
  return null;
}

function formatTimestampUrl(url, timestamp, videoType) {
  if (!videoType) return url;

  const split_time = timestamp.split(':');
  const seconds = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);

  if (videoType === 'youtube') {
    return url;
  } else if (videoType === 'vimeo') {
    const vimeoMatch = url.match(/(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i);
    if (vimeoMatch) {
      return `https://vimeo.com/${vimeoMatch[1]}#t=${seconds}s`;
    }
  }
  return url;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

function getId(url) {
  // Handle YouTube IDs
  const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const youtubeMatch = url.match(youtubeRegExp);
  if (youtubeMatch && youtubeMatch[2].length === 11) {
      return { platform: 'youtube', id: youtubeMatch[2] };
  }

  // Handle Vimeo IDs
  const vimeoRegExp = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
  const vimeoMatch = url.match(vimeoRegExp);
  if (vimeoMatch) {
      return { platform: 'vimeo', id: vimeoMatch[1] };
  }

  return null;
}

function convertTimestampToSeconds(timestamp) {
  if (!timestamp) return 0;
  const split_time = timestamp.split(':');
  // Handle HH:MM:SS format
  return (+split_time[0]) * 3600 + (+split_time[1]) * 60 + (+split_time[2]);
}

// Add a helper function to format seconds back to HH:MM:SS
function formatSecondsToTimestamp(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

const Transcript = (props) => {
  const [allTranscript, setAllTranscript] = useState([])
  const [selectedRange, setSelectedRange] = useState({ start: null, end: null })
  const [isSelecting, setIsSelecting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [embedId, setEmbedId] = useState(null)
  const [copyFeedback, setCopyFeedback] = useState(false)
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const [error, setError] = useState(null)
  const [embedError, setEmbedError] = useState(null)
  const [currentSummarySection, setCurrentSummarySection] = useState(null)
  const [openTopics, setOpenTopics] = useState(new Set())
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(true)
  const scrollTargetRef = useRef(null);
  const iframeRef = useRef(null);
  const hasScrolledRef = useRef(false);
  const scrollContainerRef = useRef(null);

  // Function to handle scrolling to segment - MOVED UP before useEffect hooks
  const scrollToSegment = useCallback((segmentTimestamp) => {
    if (!allTranscript.length) {
      console.log('Transcript not loaded yet, skipping scroll');
      return;
    }

    const segmentIndex = allTranscript.findIndex(segment => 
      segment.timestamp === segmentTimestamp
    );

    if (segmentIndex === -1) return;

    // Get scroll container from ref instead of query selector
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      console.log('Scroll container not found, skipping scroll');
      return;
    }

    // Cancel any existing scroll animations
    scrollContainer.style.scrollBehavior = 'auto';
    scrollContainer.scrollTop = scrollContainer.scrollTop;

    // Store the animation frame ID so we can cancel it if needed
    const animationFrameId = requestAnimationFrame(() => {
      const isMobile = window.innerWidth < 640;
      const elementId = `transcript-segment-${isMobile ? 'mobile' : 'desktop'}-${segmentIndex}`;
      const element = document.getElementById(elementId);
      
      if (element && scrollContainer) {
        scrollContainer.style.scrollBehavior = 'smooth';
        const scrollTop = element.offsetTop - 100;
        scrollContainer.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        });
      }
    });

    // Return cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
      if (scrollContainer) {
        scrollContainer.style.scrollBehavior = 'auto';
      }
    };
  }, [allTranscript]);

  // Reset all state when video URL changes
  useEffect(() => {
    hasScrolledRef.current = false;
    setIsIframeLoading(true);
    setIsTranscriptLoading(true);
    setError(null);
    setEmbedError(null);
    setSelectedRange({ start: null, end: null });
    setIsSelecting(false);
  }, [props.resultdata.video_url.raw]);

  // Memoize event handlers to prevent unnecessary re-creation
  const handleEsc = React.useCallback((event) => {
    if (event.key === 'Escape') {
      setShowModal(false);
    }
  }, []); // No dependencies since setShowModal is stable

  // Cleanup for escape key listener
  useEffect(() => {
    if (showModal) {
      window.addEventListener('keydown', handleEsc);
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [showModal, handleEsc]);

  // Cleanup for iframe message listener
  useEffect(() => {
    const handleIframeMessage = (event) => {
      // Only handle messages from our iframe
      if (iframeRef.current && event.source === iframeRef.current.contentWindow) {
        // Handle any iframe messages here
        console.log('Received message from iframe:', event.data);
      }
    };

    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  // Handle initial scroll when transcript loads
  useEffect(() => {
    // Reset scroll state when transcript changes
    hasScrolledRef.current = false;

    if (!allTranscript.length || !props.targetSegment || isTranscriptLoading) {
      return;
    }

    let timeoutId;
    let cleanupScroll;

    const performScroll = () => {
      if (!hasScrolledRef.current) {
        cleanupScroll = scrollToSegment(props.targetSegment);
        hasScrolledRef.current = true;
      }
    };

    // First attempt immediate scroll
    performScroll();

    // Backup scroll after a delay to ensure DOM is ready
    timeoutId = setTimeout(performScroll, 300);

    return () => {
      clearTimeout(timeoutId);
      if (cleanupScroll) {
        cleanupScroll();
      }
    };
  }, [allTranscript, props.targetSegment, scrollToSegment, isTranscriptLoading]);

  // Load transcript when video URL changes
  useEffect(() => {
    let isCancelled = false;
    const controller = new AbortController();

    const loadTranscript = async () => {
      const videoUrl = props.resultdata.video_url.raw;
      console.log('Loading transcript for video:', {
        url: videoUrl,
        targetSegment: props.resultdata.segment_start?.raw
      });

      const options = {
        filters: { video_url: videoUrl },
        sort: { "segment_start": "asc" },
        result_fields: { transcript: { raw: {} }, timestamp_url: { raw: {} }, segment_start: { raw: {} } },
        page: { size: 500 }
      };

      try {
        setError(null);
        const resultList = await client.search("", options);
        
        // Only update state if the component is still mounted and this is the latest request
        if (!isCancelled) {
          const allTrans = resultList.results.map(result => ({
            transcript: result.getRaw("transcript"),
            timestamp: result.getRaw("segment_start"),
            url: result.getRaw("timestamp_url")
          }));
          setAllTranscript(allTrans);
          
          // Add a small delay before setting loading to false to ensure DOM updates
          setTimeout(() => {
            if (!isCancelled) {
              setIsTranscriptLoading(false);
            }
          }, 100);
        }
      } catch (error) {
        // Only update error state if the component is still mounted and this isn't a cancelled request
        if (!isCancelled && error.name !== 'AbortError') {
          console.error('Error loading transcript:', error);
          setError('Failed to load transcript. Please try again later.');
          setAllTranscript([]);
          setIsTranscriptLoading(false);
        }
      }
    };

    setAllTranscript([]); // Clear transcript when URL changes
    loadTranscript();

    // Cleanup function to handle unmounting or URL changes
    return () => {
      isCancelled = true;
      controller.abort();
    };
  }, [props.resultdata.video_url.raw]);

  const handleIframeLoad = useCallback(() => {
    setIsIframeLoading(false);
  }, []);

  const handleCheckboxChange = (index) => {
    if (!isSelecting) {
      // Start new selection
      setIsSelecting(true);
      setSelectedRange({ start: index, end: index });
    } else {
      // Update existing selection
      setIsSelecting(false);
      if (index < selectedRange.start) {
        setSelectedRange({ ...selectedRange, end: selectedRange.start, start: index });
      } else {
        setSelectedRange({ ...selectedRange, end: index });
      }
    }
  };

  const createEmbed = async () => {
    if (!selectedRange.start && !selectedRange.end) return;

    try {
      setEmbedError(null);
      // Get the selected segments from our existing transcript data
      const selectedSegments = allTranscript.slice(
        selectedRange.start,
        selectedRange.end + 1
      ).map(segment => ({
        timestamp: segment.timestamp,
        text: segment.transcript
      }));

      // Get the video URL from the result data
      const videoUrl = props.resultdata.video_url?.raw || 
                      props.resultdata.timestamp_url?.raw?.split('&t=')[0];

      const response = await fetch('/user_auth/embed/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
          'Authorization': `Token ${localStorage.getItem('auth-token')}`
        },
        body: JSON.stringify({
          video_url: videoUrl,
          start_time: allTranscript[selectedRange.start].timestamp,
          end_time: selectedRange.end < allTranscript.length - 1 
            ? allTranscript[selectedRange.end + 1].timestamp 
            : null,
          segments: selectedSegments
        })
      });

      const data = await response.json();
      if (response.ok) {
        setEmbedId(data.id);
        setShowModal(true);
        setSelectedRange({ start: null, end: null });
      } else {
        throw new Error(data.error || 'Failed to create embed');
      }
    } catch (error) {
      console.error('Error creating embed:', error);
      setEmbedError(error.message || 'Failed to create embed. Please try again.');
    }
  };

  const getEmbedUrl = () => {
    const link = props.resultdata.timestamp_url.raw;
    const video = getId(link);
    let embedUrl = '';
    
    if (video) {
      if (video.platform === 'youtube') {
        // Only use start time if it's a search result (props.targetSegment is present)
        if (props.targetSegment) {
          const split_time = props.targetSegment.split(':');
          const start = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);
          console.log('Initializing YouTube iframe with start time:', props.targetSegment, start);
          embedUrl = `https://www.youtube.com/embed/${video.id}?start=${start}&enablejsapi=1&origin=${window.location.origin}&playsinline=1`;
        } else {
          // For latest meetings, start from beginning
          console.log('Latest meeting - starting from beginning');
          embedUrl = `https://www.youtube.com/embed/${video.id}?enablejsapi=1&origin=${window.location.origin}&playsinline=1`;
        }
      } else if (video.platform === 'vimeo') {
        // Only use start time if it's a search result
        if (props.targetSegment) {
          const split_time = props.targetSegment.split(':');
          const start = (+split_time[0]) * 60*60 + (+split_time[1]) * 60 + (+split_time[2]);
          embedUrl = `https://player.vimeo.com/video/${video.id}#t=${start}s`;
        } else {
          // For latest meetings, start from beginning
          embedUrl = `https://player.vimeo.com/video/${video.id}`;
        }
      }
    }
    return embedUrl;
  };

  const handleTimestampClick = (e, timestamp) => {
    e.preventDefault();
    const iframe = iframeRef.current;
    if (!iframe) return;

    // Scroll to the clicked timestamp
    scrollToSegment(timestamp);

    const seconds = convertTimestampToSeconds(timestamp);
    const video = getId(props.resultdata.timestamp_url.raw);
    
    if (video?.platform === 'youtube') {
      // YouTube postMessage API
      console.log('Sending seekTo command for timestamp:', timestamp, 'seconds:', seconds);
      
      // First, ensure the player is ready
      iframe.contentWindow.postMessage(JSON.stringify({
        event: 'command',
        func: 'seekTo',
        args: [seconds]
      }), '*');

      // Send play command with a small delay to ensure seek completes
      setTimeout(() => {
        console.log('Sending playVideo command after seek');
        iframe.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'playVideo',
          args: []
        }), '*');
      }, 100); // Increased delay to ensure seek completes
    } else if (video?.platform === 'vimeo') {
      // Vimeo postMessage API
      iframe.contentWindow.postMessage({
        method: 'seekTo',
        value: seconds
      }, '*');
    }
  };

  // Function to find the current summary section based on timestamp
  const findCurrentSection = useCallback((timestamp) => {
    if (!props.meetingSummary || !timestamp) return null;
    
    const timeInSeconds = convertTimestampToSeconds(timestamp);
    
    return props.meetingSummary.find((section, index) => {
      const sectionStart = convertTimestampToSeconds(section.start_timecode);
      const sectionEnd = convertTimestampToSeconds(section.end_timecode);
      return timeInSeconds >= sectionStart && timeInSeconds < sectionEnd;
    });
  }, [props.meetingSummary]);

  // Update current section when scrolling
  const handleScroll = useCallback((e) => {
    if (!scrollContainerRef.current || !allTranscript.length) return;

    const container = scrollContainerRef.current;
    const elements = container.getElementsByClassName('transcript-row');
    const containerTop = container.scrollTop;
    const containerHeight = container.clientHeight;

    // Find the first visible transcript row
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const elementTop = element.offsetTop - container.offsetTop;
      const elementBottom = elementTop + element.clientHeight;

      if (elementTop <= containerTop + containerHeight/2 && elementBottom >= containerTop) {
        const timestamp = allTranscript[i].timestamp;
        const section = findCurrentSection(timestamp);
        if (section !== currentSummarySection) {
          setCurrentSummarySection(section);
        }
        break;
      }
    }
  }, [allTranscript, findCurrentSection, currentSummarySection]);

  // Add scroll event listener
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Add function to handle topic toggle
  const toggleTopic = useCallback((topicId) => {
    setOpenTopics(prev => {
      const newSet = new Set(prev);
      if (newSet.has(topicId)) {
        newSet.delete(topicId);
      } else {
        newSet.add(topicId);
      }
      return newSet;
    });
  }, []);

  // Add effect to open the topic containing the target segment
  useEffect(() => {
    if (!props.targetSegment || !props.meetingSummary) return;

    const targetTime = convertTimestampToSeconds(props.targetSegment);
    
    console.log('Opening topic for target segment:', {
      targetSegment: props.targetSegment,
      targetTimeInSeconds: targetTime,
      formattedTargetTime: formatSecondsToTimestamp(targetTime)
    });
    
    // Clear previously open topics when target segment changes
    setOpenTopics(new Set());
    
    for (const section of props.meetingSummary) {
      // Find the last topic that starts before our target time
      let matchingTopic = null;
      for (const topic of section.topics || []) {
        const topicStart = convertTimestampToSeconds(topic.start_timecode);
        const topicEnd = convertTimestampToSeconds(topic.end_timecode);
        const nextTopic = section.topics[section.topics.indexOf(topic) + 1];
        const nextTopicStart = nextTopic ? convertTimestampToSeconds(nextTopic.start_timecode) : Infinity;
        
        // Match if segment is within topic's time range
        if (targetTime >= topicStart && targetTime <= topicEnd) {
          matchingTopic = topic;
          break;
        }
        
        // Match if segment is in gap after this topic but before next topic
        if (nextTopic && targetTime > topicEnd && targetTime < nextTopicStart) {
          matchingTopic = topic;
          break;
        }
      }
      
      if (matchingTopic) {
        const topicId = `${section.parliamentary_section_name}-${matchingTopic.topic_name}`;
        console.log('Found matching topic, opening:', {
          topicId,
          topic: matchingTopic.topic_name,
          timeRange: `${matchingTopic.start_timecode} - ${matchingTopic.end_timecode}`,
          targetTime: props.targetSegment
        });
        
        setOpenTopics(prev => new Set([...prev, topicId]));
        return; // Exit once we find the matching topic
      }
    }
  }, [props.targetSegment, props.meetingSummary]); // Only run when target segment or meeting summary changes

  return (
    <div className="Transcript h-full flex flex-col">
      { error ? (
        <div className="p-4 text-red-700 bg-red-100 rounded-md">
          <p className="font-medium">Error</p>
          <p>{error}</p>
        </div>
      ) : props.isLoading || isTranscriptLoading ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-theme-blue"></div>
            <span className="mt-4 text-gray-600 text-lg">Loading transcript...</span>
          </div>
        </div>
      ) : allTranscript.length === 0 ? (
        <div>No transcript available</div>
      ) : (
        <>
          {/* Fixed top section */}
          <div className="flex flex-col sm:flex-row gap-8 mb-4">
            {/* Video Metadata (full width on mobile, 1/3 width on desktop) */}
            <div className="w-full sm:w-1/3 order-2 sm:order-1 sm:pl-6">
              <h2 className="text-theme-black font-regular font-bold text-2xl mb-2">{props.resultdata.video_title.raw.replaceAll('"','')}</h2>
              <h3 className="text-gray-600 text-xl mb-2">{props.resultdata.locality.raw.replaceAll("_"," ").replaceAll(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, {props.resultdata.state.raw.toUpperCase()}</h3>
              <p className="text-gray-600">Uploaded on {formatDate(props.resultdata.upload_date.raw)}</p>
              <p className="text-gray-600">Total time: {props.resultdata.video_length.raw}</p>
            </div>

            {/* Video Embed (full width on mobile, 2/3 width on desktop) */}
            <div className="w-full sm:w-2/3 order-1 sm:order-2">
              <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
                { getId(props.resultdata.timestamp_url.raw) && (
                  <>
                    {isIframeLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                        <div className="flex flex-col items-center">
                          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-theme-blue"></div>
                          <span className="mt-2 text-gray-600">Loading video...</span>
                        </div>
                      </div>
                    )}
                    <iframe 
                      ref={iframeRef}
                      id="youtube-player"
                      title="video-embed" 
                      className="w-full absolute inset-0 h-full" 
                      style={{
                        opacity: isIframeLoading ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out'
                      }}
                      src={getEmbedUrl()} 
                      frameBorder="0" 
                      allow="autoplay"
                      allowFullScreen
                      onLoad={handleIframeLoad}
                    ></iframe>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Show embed error if exists */}
          {embedError && (
            <div className="w-full mb-4">
              <div className="p-4 text-red-700 bg-red-100 rounded-md">
                <p>{embedError}</p>
              </div>
            </div>
          )}

          {/* Scrollable bottom section */}
          <div 
            ref={scrollContainerRef} 
            className="flex-1 overflow-auto min-h-0 pb-4 relative border-2 border-theme-blue/50"
          >
            {/* Desktop Table */}
            <div className="hidden sm:block px-4">
              <table className="w-full table-auto text-sm text-left text-gray-500">
                <colgroup>
                  <col className="w-[30%]" />
                  <col />
                  <col className="w-[120px]" />
                  <col className="w-[40px]" />
                </colgroup>
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3 font-semibold">Meeting Summary</th>
                    <th scope="col" className="px-6 py-3 font-semibold">Transcript</th>
                    <th scope="col" className="pr-2 py-3 font-semibold">Time</th>
                    <th scope="col" className="pr-2 py-3 font-semibold"></th>
                  </tr>
                </thead>
                <tbody>
                  {props.meetingSummary ? (
                    // Render with meeting summary structure if available
                    props.meetingSummary?.map((section, sectionIndex) => {
                      return (
                        <React.Fragment key={`desktop-${sectionIndex}`}>
                          {/* Parliamentary Section Header Row */}
                          <tr className="bg-gray-50 border-t-2 border-theme-blue/20">
                            <td className="px-6 py-4">
                              <span className="text-lg font-semibold text-theme-blue">
                                {section.parliamentary_section_name}
                              </span>
                            </td>
                            <td className="px-6 py-4"></td>
                            <td className="pr-2 py-4 text-sm text-gray-600">
                              <span className="whitespace-nowrap">{section.start_timecode} -</span><br />
                              {section.end_timecode}
                            </td>
                            <td className="pr-2 py-4"></td>
                          </tr>

                          {/* Render topics within this parliamentary section */}
                          {(section.topics || []).map((topic, topicIndex) => {
                            const topicId = `${section.parliamentary_section_name}-${topic.topic_name}`;
                            const isOpen = openTopics.has(topicId);
                            const topicStart = convertTimestampToSeconds(topic.start_timecode);
                            const topicEnd = convertTimestampToSeconds(topic.end_timecode);
                            const nextTopic = section.topics[topicIndex + 1];
                            const nextTopicStart = nextTopic ? convertTimestampToSeconds(nextTopic.start_timecode) : Infinity;

                            // Get all transcript rows for this topic - include segments up until the start of the next topic
                            const topicRows = allTranscript.filter(item => {
                              const time = convertTimestampToSeconds(item.timestamp);
                              
                              // If segment is within topic's time range
                              if (time >= topicStart && time <= topicEnd) {
                                return true;
                              }
                              
                              // If segment is in gap after this topic but before next topic
                              if (nextTopic && time > topicEnd && time < nextTopicStart) {
                                return true;
                              }
                              
                              return false;
                            });

                            return (
                              <React.Fragment key={`desktop-${topicId}`}>
                                {/* Topic Header */}
                                <tr className="bg-gray-50/50 border-t border-theme-blue/10">
                                  <td className="px-6 py-3">
                                    <div 
                                      className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-2"
                                      onClick={() => toggleTopic(topicId)}
                                    >
                                      <svg 
                                        className={`w-4 h-4 mr-2 transform transition-transform ${isOpen ? 'rotate-90' : ''}`} 
                                        fill="currentColor" 
                                        viewBox="0 0 20 20"
                                      >
                                        <path fillRule="evenodd" d="M7.293 4.707a1 1 0 010 1.414L11.586 10l-4.293 4.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 0z" clipRule="evenodd"/>
                                      </svg>
                                      <span className="text-md font-medium text-theme-blue">
                                        {topic.topic_name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="px-6 py-3"></td>
                                  <td className="pr-2 py-3 text-sm text-gray-600">
                                    <span className="whitespace-nowrap">{topic.start_timecode} -</span><br />
                                    {topic.end_timecode}
                                  </td>
                                  <td className="pr-2 py-3"></td>
                                </tr>

                                {/* Topic Content - Only show if topic is open */}
                                {isOpen && (
                                  <>
                                    {topicRows.map((row, rowIndex) => (
                                      <tr 
                                        key={`desktop-${topicId}-row-${rowIndex}`}
                                        id={`transcript-segment-desktop-${allTranscript.findIndex(t => t.timestamp === row.timestamp)}`}
                                        className="bg-white border-b"
                                      >
                                        {rowIndex === 0 ? (
                                          <td className="px-6 py-4 align-top w-[30%]" rowSpan={topicRows.length}>
                                            <div className="rounded-lg">
                                              <div className="text-sm text-gray-600">
                                                <div>Duration: {topic.duration}</div>
                                              </div>
                                              {topic.summary_of_topic && (
                                                <p className="text-sm mt-2">{topic.summary_of_topic}</p>
                                              )}
                                              {topic.speakers?.length > 0 && (
                                                <div className="mt-2">
                                                  <h5 className="text-sm font-medium">Speakers:</h5>
                                                  <ul className="text-sm list-disc list-inside">
                                                    {topic.speakers.map((speaker, idx) => (
                                                      <li key={idx}>{speaker}</li>
                                                    ))}
                                                  </ul>
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        ) : null}
                                        <td className="px-6 py-4 align-top">
                                          {row.transcript === '0' ? (
                                            <span>no speech</span>
                                          ) : (
                                            <div className="pr-4">
                                              <Highlighter
                                                searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                                autoEscape={true}
                                                textToHighlight={row.transcript}
                                              />
                                            </div>
                                          )}
                                        </td>
                                        <td className="pr-2 py-4 align-top">
                                          <button 
                                            onClick={(e) => handleTimestampClick(e, row.timestamp)}
                                            className="font-regular text-s text-theme-hyperlink hover:underline whitespace-nowrap"
                                          >
                                            {row.timestamp}
                                          </button>
                                        </td>
                                        <td className="pr-2 py-4 align-top">
                                          <input
                                            type="checkbox"
                                            checked={selectedRange.start !== null && 
                                              allTranscript.findIndex(t => t.timestamp === row.timestamp) >= Math.min(selectedRange.start, selectedRange.end) && 
                                              allTranscript.findIndex(t => t.timestamp === row.timestamp) <= Math.max(selectedRange.start, selectedRange.end)}
                                            onChange={() => handleCheckboxChange(allTranscript.findIndex(t => t.timestamp === row.timestamp))}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 flex-shrink-0 mt-0.5 ml-3"
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    // Render simple transcript table when no meeting summary is available
                    allTranscript.map((row, index) => (
                      <tr 
                        key={`desktop-transcript-${index}`}
                        id={`transcript-segment-desktop-${index}`}
                        className="bg-white border-b"
                      >
                        <td className="px-6 py-4 align-top">
                          {/* Empty summary column */}
                        </td>
                        <td className="px-6 py-4 align-top">
                          {row.transcript === '0' ? (
                            <span>no speech</span>
                          ) : (
                            <div className="pr-4">
                              <Highlighter
                                searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                autoEscape={true}
                                textToHighlight={row.transcript}
                              />
                            </div>
                          )}
                        </td>
                        <td className="pr-2 py-4 align-top">
                          <button 
                            onClick={(e) => handleTimestampClick(e, row.timestamp)}
                            className="font-regular text-s text-theme-hyperlink hover:underline whitespace-nowrap"
                          >
                            {row.timestamp}
                          </button>
                        </td>
                        <td className="pr-2 py-4 align-top">
                          <input
                            type="checkbox"
                            checked={selectedRange.start !== null && 
                              index >= Math.min(selectedRange.start, selectedRange.end) && 
                              index <= Math.max(selectedRange.start, selectedRange.end)}
                            onChange={() => handleCheckboxChange(index)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 flex-shrink-0 mt-0.5 ml-3"
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Mobile Table */}
            <div className="sm:hidden px-4">
              <table className="w-full table-auto text-sm text-left text-gray-500">
                <colgroup>
                  <col className="w-[30%]" />
                  <col />
                  <col className="w-[120px]" />
                  <col className="w-[40px]" />
                </colgroup>
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3 font-semibold">Meeting Summary</th>
                    <th scope="col" className="px-6 py-3 font-semibold">Transcript</th>
                    <th scope="col" className="pr-2 py-3 font-semibold">Time</th>
                    <th scope="col" className="pr-2 py-3 font-semibold"></th>
                  </tr>
                </thead>
                <tbody>
                  {props.meetingSummary ? (
                    // Render with meeting summary structure if available
                    props.meetingSummary?.map((section, sectionIndex) => {
                      return (
                        <React.Fragment key={`mobile-${sectionIndex}`}>
                          {/* Parliamentary Section Header Row */}
                          <tr className="bg-gray-50 border-t-2 border-theme-blue/20">
                            <td className="px-6 py-4">
                              <span className="text-lg font-semibold text-theme-blue">
                                {section.parliamentary_section_name}
                              </span>
                            </td>
                            <td className="px-6 py-4"></td>
                            <td className="pr-2 py-4 text-sm text-gray-600">
                              <span className="whitespace-nowrap">{section.start_timecode} -</span><br />
                              {section.end_timecode}
                            </td>
                            <td className="pr-2 py-4"></td>
                          </tr>

                          {/* Render topics within this parliamentary section */}
                          {(section.topics || []).map((topic, topicIndex) => {
                            const topicId = `${section.parliamentary_section_name}-${topic.topic_name}`;
                            const isOpen = openTopics.has(topicId);
                            const topicStart = convertTimestampToSeconds(topic.start_timecode);
                            const topicEnd = convertTimestampToSeconds(topic.end_timecode);
                            const nextTopic = section.topics[topicIndex + 1];
                            const nextTopicStart = nextTopic ? convertTimestampToSeconds(nextTopic.start_timecode) : Infinity;

                            // Get all transcript rows for this topic - include segments up until the start of the next topic
                            const topicRows = allTranscript.filter(item => {
                              const time = convertTimestampToSeconds(item.timestamp);
                              
                              // If segment is within topic's time range
                              if (time >= topicStart && time <= topicEnd) {
                                return true;
                              }
                              
                              // If segment is in gap after this topic but before next topic
                              if (nextTopic && time > topicEnd && time < nextTopicStart) {
                                return true;
                              }
                              
                              return false;
                            });

                            return (
                              <React.Fragment key={`mobile-${topicId}`}>
                                {/* Topic Header */}
                                <tr className="bg-gray-50/50 border-t border-theme-blue/10">
                                  <td className="px-6 py-3">
                                    <div 
                                      className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-2"
                                      onClick={() => toggleTopic(topicId)}
                                    >
                                      <svg 
                                        className={`w-4 h-4 mr-2 transform transition-transform ${isOpen ? 'rotate-90' : ''}`} 
                                        fill="currentColor" 
                                        viewBox="0 0 20 20"
                                      >
                                        <path fillRule="evenodd" d="M7.293 4.707a1 1 0 010 1.414L11.586 10l-4.293 4.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 0z" clipRule="evenodd"/>
                                      </svg>
                                      <span className="text-md font-medium text-theme-blue">
                                        {topic.topic_name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="px-6 py-3"></td>
                                  <td className="pr-2 py-3 text-sm text-gray-600">
                                    <span className="whitespace-nowrap">{topic.start_timecode} -</span><br />
                                    {topic.end_timecode}
                                  </td>
                                  <td className="pr-2 py-3"></td>
                                </tr>

                                {/* Topic Content - Only show if topic is open */}
                                {isOpen && (
                                  <>
                                    {topicRows.map((row, rowIndex) => (
                                      <tr 
                                        key={`mobile-${topicId}-row-${rowIndex}`}
                                        id={`transcript-segment-mobile-${allTranscript.findIndex(t => t.timestamp === row.timestamp)}`}
                                        className="bg-white border-b"
                                      >
                                        {rowIndex === 0 ? (
                                          <td className="px-6 py-4 align-top w-[30%]" rowSpan={topicRows.length}>
                                            <div className="rounded-lg">
                                              <div className="text-sm text-gray-600">
                                                <div>Duration: {topic.duration}</div>
                                              </div>
                                              {topic.summary_of_topic && (
                                                <p className="text-sm mt-2">{topic.summary_of_topic}</p>
                                              )}
                                              {topic.speakers?.length > 0 && (
                                                <div className="mt-2">
                                                  <h5 className="text-sm font-medium">Speakers:</h5>
                                                  <ul className="text-sm list-disc list-inside">
                                                    {topic.speakers.map((speaker, idx) => (
                                                      <li key={idx}>{speaker}</li>
                                                    ))}
                                                  </ul>
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        ) : null}
                                        <td className="px-6 py-4 align-top">
                                          {row.transcript === '0' ? (
                                            <span>no speech</span>
                                          ) : (
                                            <div className="pr-4">
                                              <Highlighter
                                                searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                                autoEscape={true}
                                                textToHighlight={row.transcript}
                                              />
                                            </div>
                                          )}
                                        </td>
                                        <td className="pr-2 py-4 align-top">
                                          <button 
                                            onClick={(e) => handleTimestampClick(e, row.timestamp)}
                                            className="font-regular text-s text-theme-hyperlink hover:underline whitespace-nowrap"
                                          >
                                            {row.timestamp}
                                          </button>
                                        </td>
                                        <td className="pr-2 py-4 align-top">
                                          <input
                                            type="checkbox"
                                            checked={selectedRange.start !== null && 
                                              allTranscript.findIndex(t => t.timestamp === row.timestamp) >= Math.min(selectedRange.start, selectedRange.end) && 
                                              allTranscript.findIndex(t => t.timestamp === row.timestamp) <= Math.max(selectedRange.start, selectedRange.end)}
                                            onChange={() => handleCheckboxChange(allTranscript.findIndex(t => t.timestamp === row.timestamp))}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 flex-shrink-0 mt-0.5 ml-3"
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    // Render simple transcript table when no meeting summary is available
                    allTranscript.map((row, index) => (
                      <tr 
                        key={`mobile-transcript-${index}`}
                        id={`transcript-segment-mobile-${index}`}
                        className="bg-white border-b"
                      >
                        <td className="px-6 py-4 align-top">
                          {/* Empty summary column */}
                        </td>
                        <td className="px-6 py-4 align-top">
                          {row.transcript === '0' ? (
                            <span>no speech</span>
                          ) : (
                            <div className="pr-4">
                              <Highlighter
                                searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                                autoEscape={true}
                                textToHighlight={row.transcript}
                              />
                            </div>
                          )}
                        </td>
                        <td className="pr-2 py-4 align-top">
                          <button 
                            onClick={(e) => handleTimestampClick(e, row.timestamp)}
                            className="font-regular text-s text-theme-hyperlink hover:underline whitespace-nowrap"
                          >
                            {row.timestamp}
                          </button>
                        </td>
                        <td className="pr-2 py-4 align-top">
                          <input
                            type="checkbox"
                            checked={selectedRange.start !== null && 
                              index >= Math.min(selectedRange.start, selectedRange.end) && 
                              index <= Math.max(selectedRange.start, selectedRange.end)}
                            onChange={() => handleCheckboxChange(index)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 flex-shrink-0 mt-0.5 ml-3"
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Floating embed button - moved outside scroll container for cleaner structure */}
          {selectedRange.start !== null && selectedRange.end !== null && (
            <div className="fixed bottom-8 right-8 z-10">
              <button
                onClick={createEmbed}
                className="bg-theme-blue text-white px-4 py-2 rounded-lg shadow-lg hover:bg-theme-blue/90 transition-colors flex items-center gap-2"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                </svg>
                Create Embed
              </button>
            </div>
          )}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Embed Code
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md">
                  <code className="text-sm text-gray-800 dark:text-gray-200">
                    {`<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`}
                  </code>
                </div>
                <div className="mt-4 flex justify-end items-center gap-2">
                  {copyFeedback && (
                    <span className="text-green-600 dark:text-green-400 text-sm">
                      Copied to clipboard!
                    </span>
                  )}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`
                      )
                      setCopyFeedback(true)
                      setTimeout(() => setCopyFeedback(false), 2000) // Hide after 2 seconds
                    }}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Copy Code
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
};

Transcript.propTypes = {
  resultdata: PropTypes.shape({
    video_url: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    video_title: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    locality: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    state: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    upload_date: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    video_length: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    timestamp_url: PropTypes.shape({
      raw: PropTypes.string.isRequired
    }).isRequired,
    segment_start: PropTypes.shape({
      raw: PropTypes.string
    })
  }).isRequired,
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool
};

// Wrap the component with the error boundary
const WrappedTranscript = (props) => (
  <TranscriptErrorBoundary>
    <Transcript {...props} />
  </TranscriptErrorBoundary>
);

export default WrappedTranscript; 